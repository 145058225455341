import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faImages, faPlay } from "@fortawesome/pro-regular-svg-icons";

import Button from "components/Button";

import { openLink } from "utils";

import blobArenaColosseum from "assets/images/blob-arena-colosseum.png";
import blobArenaCloud from "assets/images/blob-arena-cloud.png";
import blobArenaLogo from "assets/images/blob-arena-logo.png";
import blobSword from "assets/images/blob-sword.png";
import blobShield from "assets/images/blob-shield.png";
import realmsWorldLogo from "assets/images/realms-world.png";

import style from "./style.module.scss";

const BlobArena = ({ onOpenGallery }) => {
  const openGallery = () => {
    onOpenGallery([
      require("assets/images/blob-arena-gallery/1.jpg"),
      require("assets/images/blob-arena-gallery/2.jpg"),
      require("assets/images/blob-arena-gallery/3.jpg"),
      require("assets/images/blob-arena-gallery/4.jpg"),
    ]);
  };

  return (
    <div
      id="blob-arena-content"
      className={twMerge(style.blob_arena, "relative z-0 h-screen w-full")}
    >
      <img
        src={blobArenaCloud}
        alt="colosseum"
        className={twMerge(
          style.cloud,
          "large-cloud absolute object-contain object-top"
        )}
      />
      <img
        src={blobArenaCloud}
        alt="colosseum"
        className={twMerge(style.cloud, "absolute object-contain object-top")}
      />
      <img
        src={blobArenaColosseum}
        alt="colosseum"
        className="absolute h-full w-full object-cover object-top"
      />
      <div
        id="blob-arena-main-overlay"
        className={twMerge(
          "absolute z-[1] flex h-full w-full flex-col items-center justify-center bg-grugBlack bg-opacity-65 px-4",
          "smallDesktop:px-0"
        )}
      >
        <img
          src={blobArenaLogo}
          alt="blob-arena"
          className={twMerge(
            "-mt-[70%] max-w-[250px]",
            "smallDesktop:mt-[max(-20%,-200px)] smallDesktop:w-[514px] smallDesktop:max-w-none"
          )}
        />
        <p
          className={twMerge(
            "text-sora mt-4 max-w-[760px] text-center text-base font-light text-white",
            "smallDesktop:text-2xl"
          )}
        >
          A thrilling tactical battle experience, inviting players to engage in
          strategic thinking, risk assessment, and the joy of collecting and
          battling with an ever-growing roster of Bloberts
        </p>
        <div className={twMerge("z-[1] mt-8 flex gap-4", "smallDesktop:mt-10")}>
          <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={() =>
              openLink("https://github.com/grugslair/blob-arena-contracts")
            }
            className="w-[60px] justify-center border border-white bg-[#0B0B0B33]"
          >
            <FontAwesomeIcon icon={faGithub} className="text-2xl" />
          </Button>
          {/* <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={() => openLink("https://test-build-ba.vercel.app/")}
            className="whitespace-nowrap"
          >
            <FontAwesomeIcon icon={faPlay} className="-mt-1 mr-2 text-lg" />
            Play Now
          </Button> */}
          <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={openGallery}
            className="w-[60px] justify-center border border-white bg-[#0B0B0B33]"
          >
            <FontAwesomeIcon icon={faImages} className="text-2xl" />
          </Button>
        </div>
        <div
          className="absolute bottom-[15%] flex cursor-pointer items-center gap-2"
          onClick={() => openLink("https://realms.world/games/blob-arena")}
        >
          <p
            className={twMerge(
              "text-sora text-sm font-light text-grayCool300",
              "smallDesktop:text-2xl"
            )}
          >
            Supported by:
          </p>
          <img
            src={realmsWorldLogo}
            alt="realms-world"
            className={twMerge("w-[74px]", "smallDesktop:w-[117px]")}
          />
        </div>
      </div>
      <div
        className={twMerge(
          "pointer-events-none absolute bottom-[8%] z-[1] -ml-[15%] flex w-[130%]",
          "screen640:ml-0 screen640:w-full"
        )}
      >
        <div
          className={twMerge(
            style.floating_blob,
            "z-[1] -mr-[36px] flex flex-1 justify-end",
            "smallDesktop:-mr-[48px]",
            window.innerHeight >= 1200 && "smallDesktop:-mr-[68px]"
          )}
        >
          <div
            id="blob-arena-sword"
            className={twMerge(
              "relative w-[240px] min-w-[240px]",
              "smallDesktop:w-[320px] smallDesktop:min-w-[320px]",
              window.innerHeight >= 1200 &&
                "smallDesktop:w-[480px] smallDesktop:min-w-[480px]"
            )}
          >
            <div
              className={twMerge(
                style.blobShadow,
                "-bottom-[16px] smallDesktop:-bottom-[20px]",
                window.innerHeight >= 1200 && "smallDesktop:-bottom-[36px]"
              )}
            />
            <img
              src={blobSword}
              alt="blob-sword"
              className="relative w-full object-contain"
            />
          </div>
        </div>

        <div
          className={twMerge(
            style.floating_blob,
            "right-blob -ml-[36px] flex flex-1 justify-start",
            "smallDesktop:-ml-[48px]",
            window.innerHeight >= 1200 && "smallDesktop:-ml-[68px]"
          )}
        >
          <div
            id="blob-arena-shield"
            className={twMerge(
              "relative w-[240px] min-w-[240px]",
              "smallDesktop:w-[320px] smallDesktop:min-w-[320px]",
              window.innerHeight >= 1200 &&
                "smallDesktop:w-[480px] smallDesktop:min-w-[480px]"
            )}
          >
            <div className={twMerge(style.blobShadow, "bottom-[8px]")} />
            <img
              src={blobShield}
              alt="blob-shield"
              className="relative w-full object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlobArena;
