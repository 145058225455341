import React from "react";
import { twMerge } from "tailwind-merge";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import { openLink, scrollLocomotive } from "utils";
import { FOOTER_MENUS, SOCIAL_MEDIAS, getScrollOffset } from "utils/constants";

// Assets
import grugsLairLongLogo from "assets/images/grugsLairLong.png";

import style from "./style.module.scss";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";

const Footer = () => {
  return (
    <footer data-scroll-section id="footer" className="w-full">
      <div className={twMerge(style.footer_grugs, "h-[130px] w-full")} />
      <div
        className={twMerge(
          "mx-8 border-t border-solid border-t-grayCool25 border-opacity-40 py-10"
        )}
      >
        <div
          className={twMerge(
            "mx-auto flex max-w-[1376px] flex-col flex-wrap gap-10",
            "smallDesktop:flex-row smallDesktop:items-center"
          )}
        >
          <img
            src={grugsLairLongLogo}
            alt="grugs-lair"
            className="h-[40px] w-[150px] cursor-pointer"
            onClick={() =>
              scrollLocomotive(getScrollOffset().introduction, undefined, 0)
            }
          />
          <div
            className={twMerge(
              "flex flex-1 flex-col justify-center gap-8",
              "smallDesktop:flex-row smallDesktop:items-center"
            )}
          >
            {FOOTER_MENUS.map((each, i) => (
              <div
                key={i}
                className="cursor-pointer font-avara text-base font-extrabold text-white"
                onClick={each.onClick}
              >
                {each.title}
              </div>
            ))}
          </div>
          <div className="w-[150px]">
            <div
              className={twMerge(
                "flex gap-3 text-white",
                "smallDesktop:justify-end"
              )}
            >
              {SOCIAL_MEDIAS.map((each, i) => (
                <div
                  key={i}
                  className="flex size-6 cursor-pointer items-center justify-center"
                  onClick={() => openLink(each.link)}
                >
                  <FontAwesomeIcon className="text-xl" icon={each.logo} />
                </div>
              ))}
            </div>
            <a
              href="mailto:info@grugslair.com"
              className="mt-2 flex font-sora text-base font-light text-grayCool300 smallDesktop:float-right"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="relative mr-[6px] mt-1"
              />
              info@grugslair.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
