import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faImages } from "@fortawesome/pro-solid-svg-icons";
import ReactPlayer from "react-player";

import Button from "components/Button";

import { openLink } from "utils";

import risingRevenantLongLogo from "assets/images/rising-revenant-long-logo.png";
import risingRevenantLogoMobile from "assets/images/rising-revenant-mobile-logo.png";
import realmsWorldLogo from "assets/images/realms-world.png";

import style from "./style.module.scss";

const RisingRevenant = ({ onOpenGallery }) => {
  const openGallery = () => {
    onOpenGallery([
      require("assets/images/rising-revenant-gallery/1.jpg"),
      require("assets/images/rising-revenant-gallery/2.jpg"),
      require("assets/images/rising-revenant-gallery/3.jpg"),
      require("assets/images/rising-revenant-gallery/4.jpg"),
    ]);
  };

  return (
    <div
      id="rising-revenant-content"
      className={twMerge(
        style.rising_revenant,
        "relative z-[1] h-screen w-full"
      )}
    >
      <ReactPlayer
        playing
        playsinline
        loop
        muted
        width="100%"
        height="100%"
        className={style.rising_revenant_video}
        url={require("assets/videos/rising-revenant.mp4")}
      />
      <div
        id="rising-revenant-main-overlay"
        className={twMerge(
          "absolute z-[1] flex h-full w-full flex-col items-center justify-center bg-grugBlack bg-opacity-65 px-4",
          "smallDesktop:px-0"
        )}
      >
        <img
          src={
            window.innerWidth >= 1024
              ? risingRevenantLongLogo
              : risingRevenantLogoMobile
          }
          alt="rising-revenant"
          className={twMerge(
            "-mt-[20%] max-w-[250px]",
            "smallDesktop:-mt-[5%] smallDesktop:w-[840px] smallDesktop:max-w-none"
          )}
        />
        <p
          className={twMerge(
            "text-sora mt-4 max-w-[760px] text-center text-base font-light text-white",
            "smallDesktop:text-2xl"
          )}
        >
          A last man standing tactics game that blends strategic depth and
          foresight, creating a thrilling experience for strategy enthusiasts
        </p>
        <div className={twMerge("z-[1] mt-8 flex gap-4", "smallDesktop:mt-10")}>
          {/* <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={openGallery}
            className="border border-white bg-transparent"
          >
            View Gallery
          </Button>
          <Button
            className="bg-white text-primary500"
            onClick={() =>
              openLink("https://realms.world/games/rising-revenant")
            }
          >
            View on Realms.World
          </Button>
          <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={() =>
              openLink("https://github.com/grugslair/Rising-Revenant")
            }
            className="whitespace-nowrap"
          >
            <FontAwesomeIcon icon={faGithub} className="-mt-1 mr-2 text-lg" />
            White Paper
          </Button> */}
          <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={() =>
              openLink("https://github.com/grugslair/Rising-Revenant")
            }
            className="w-[60px] justify-center border border-white bg-[#0B0B0B33]"
          >
            <FontAwesomeIcon icon={faGithub} className="text-2xl" />
          </Button>
          {/* <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={() =>
              openLink("https://realms.world/games/rising-revenant")
            }
            className="whitespace-nowrap"
          >
            View on Realms.World
          </Button> */}
          <Button
            size={window.innerWidth >= 1024 ? "large" : "small"}
            onClick={openGallery}
            className="w-[60px] justify-center border border-white bg-[#0B0B0B33]"
          >
            <FontAwesomeIcon icon={faImages} className="text-2xl" />
          </Button>
        </div>
        <div
          className="absolute bottom-[15%] flex cursor-pointer items-center gap-2"
          onClick={() => openLink("https://realms.world/games/rising-revenant")}
        >
          <p
            className={twMerge(
              "text-sora text-sm font-light text-grayCool300",
              "smallDesktop:text-2xl"
            )}
          >
            Supported by:
          </p>
          <img
            src={realmsWorldLogo}
            alt="realms-world"
            className={twMerge("w-[74px]", "smallDesktop:w-[117px]")}
          />
        </div>
      </div>
    </div>
  );
};

export default RisingRevenant;
