import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import style from "./style.module.scss";

const PROFILES = [
  {
    name: "Grug",
    role: "Founder",
    description:
      "An enterprising hedge fund manager with a background in gaming development, deeply involved in the blockchain ecosystem since 2017. Focused on unlocking the potential in the onchain gaming sector, actively investing in its growth and innovation.",
    image: require("assets/team/grugs.png"),
  },
  {
    name: "Sniper1821",
    role: "Creative & art",
    description:
      "Cinema & Media Engineer and Senior Content Creator, adept in developing advanced content for innovative ICT startups. Now at the vanguard of merging Web3 and gaming, employing cuttingedge AI for unique artworks and design, they are crafting  unparalleled gaming experiences",
    image: require("assets/team/sniper1821.png"),
  },
  {
    name: "AlexHalo",
    role: "Game Developer",
    description:
      "Deeply passionate about crafting video games with cutting-edge technologies. His special interest lies in creating games that incorporate seamless procedural generation, backed by proficiency in C++, C#, and the Unity game engine.",
    image: require("assets/team/alex.png"),
  },
  {
    name: "Ben-Jay-Min",
    role: "Software Engineer",
    description:
      "Having previous experience in systems design and full stack development, Our contracts and backend engineer comes from a background in sustainable agritech as a MLOps and robotics engineer",
    image: require("assets/team/ben.jpeg"),
  },
  {
    name: "JaimeS",
    role: "Marketing",
    description:
      "Entrusted with the vital task of fueling business expansion. Consistently leveraged a robust contact network to draw in new clientele, explore emerging market opportunities, and guide the growth trajectory of various crypto projects since 2020.",
    image: require("assets/team/jaimes.png"),
  },
  {
    name: "Syn",
    role: "Product",
    description:
      "Leading Grug's Lair Tech Team. 7 years experience in Product Management, launching multiple products in Indonesian Growing sector of Financial Technology including lending and investment. Web3 Gaming player, investor, and enthusiast since 2020.",
    image: require("assets/team/syn.png"),
  },
];

const Person = ({
  index,
  name,
  role,
  description,
  image,
  active,
  isMobile,
  onClick = () => null,
}) => {
  const descriptionHeight = useRef(0);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowDescription(active), 100);
  }, [active]);

  useEffect(() => {
    const descriptionTarget = document.getElementsByClassName(
      "the-team-collapsible-description"
    )[index];
    setTimeout(() => {
      descriptionHeight.current = descriptionTarget?.offsetHeight || 0;
      if (active) {
        setTimeout(() => setShowDescription(active), 100);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={twMerge(
        "cursor-pointer border-b border-transparent transition-colors duration-500",
        active && "border-primary500"
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-4 p-3">
        <img src={image} alt="avatar" className="size-20 rounded-full" />
        <div>
          <h2
            className={twMerge(
              "font-avara text-lg font-extrabold text-white",
              "smallDesktop:text-2xl"
            )}
          >
            {name}
          </h2>
          <p
            className={twMerge(
              "mt-1 font-avara text-sm font-bold text-white",
              "smallDesktop:text-lg"
            )}
          >
            {role}
          </p>
        </div>
      </div>
      <div
        className="overflow-hidden transition-all duration-1000"
        style={{
          height: showDescription ? descriptionHeight.current + 16 : 0,
        }}
      >
        {isMobile && (
          <p className="the-team-collapsible-description pt-[10px] font-sora text-sm font-light text-grayCool300">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

const TheTeam = () => {
  const isMobile = window.innerWidth < 1024;
  const clickLock = useRef(false);
  const [selectedProfile, setSelectedProfile] = useState(0);
  const [displayedProfile, setDisplayedProfile] = useState(0);
  return !isMobile ? (
    <div
      id="the-team"
      data-scroll-section
      className="mt-20 flex w-full max-w-[1144px] flex-col items-center"
    >
      <h1 className="font-avara text-6xl font-extrabold text-white">
        The&nbsp;
        <span className="relative overflow-hidden whitespace-nowrap">
          <div
            data-scroll
            className={twMerge(
              style.the_team_title,
              "absolute -left-1 -top-2 h-full w-full",
              "tablet:-left-2"
            )}
          >
            <div
              className="absolute h-full w-[105%] bg-primary600"
              style={{
                transform:
                  "perspective(12px) rotate(2deg) rotateX(-1deg) rotateY(-0.2deg)",
              }}
            />
          </div>
          <span className="relative">Team</span>
        </span>
      </h1>
      <div className="mt-20 flex items-center gap-4">
        <div
          className={twMerge(
            "w-[448px] transition-opacity duration-500",
            selectedProfile === -1 && "opacity-0"
          )}
        >
          <img
            src={PROFILES[displayedProfile]?.image}
            alt="founder"
            className="size-[160px] rounded-full"
          />
          <h2 className="mt-[52px] font-avara text-4xl font-extrabold text-white">
            {PROFILES[displayedProfile]?.name}
          </h2>
          <p className="mt-1 font-avara text-2xl font-bold text-white">
            {PROFILES[displayedProfile]?.role}
          </p>
          <p className="mt-8 max-w-[348px] font-sora text-lg font-light text-grayCool300">
            {PROFILES[displayedProfile]?.description}
          </p>
        </div>
        <div
          className="flex w-[332px] flex-1 flex-col gap-4"
          data-scroll
          data-scroll-speed="2"
        >
          {PROFILES.map((data, i) => (
            <Person
              key={data.name}
              index={i}
              {...data}
              active={selectedProfile === i}
              onClick={() => {
                if (!clickLock.current) {
                  clickLock.current = true;
                  if (selectedProfile !== i) setSelectedProfile(-1);
                  setTimeout(() => {
                    setDisplayedProfile(i);
                    setSelectedProfile(i);
                    clickLock.current = false;
                  }, 500);
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div
      id="the-team"
      data-scroll-section
      className="mt-20 flex w-full max-w-[1144px] flex-col items-center"
    >
      <h1 className="font-avara text-4xl font-extrabold text-white">
        The&nbsp;
        <span className="relative overflow-hidden whitespace-nowrap">
          <div
            data-scroll
            className={twMerge(
              style.the_team_title,
              "absolute -left-1 -top-1 h-full w-full",
              "tablet:-left-2"
            )}
          >
            <div
              className="absolute h-full w-[105%] bg-primary600"
              style={{
                transform:
                  "perspective(12px) rotate(2deg) rotateX(-1deg) rotateY(-0.2deg)",
              }}
            />
          </div>
          <span className="relative">Team</span>
        </span>
      </h1>
      <div className="mt-10 flex w-[332px] flex-col gap-4">
        {PROFILES.map((data, i) => (
          <Person
            key={data.name}
            index={i}
            {...data}
            active={selectedProfile === i}
            onClick={() => setSelectedProfile(i)}
            isMobile
          />
        ))}
      </div>
    </div>
  );
};

export default TheTeam;
