import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import Lottie from "lottie-react";

import mouseScrollLottie from "assets/lottie/mouse-scroll.json";
import ImageGallery from "components/ImageGallery";

import RisingRevenant from "./RisingRevenant";
import BlobArena from "./BlobArena";
import style from "./style.module.scss";

const Games = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryVisible, setGalleryVisible] = useState(false);

  let offset;
  if (window.innerHeight < (window.innerWidth >= 1024 ? 840 : 700)) {
    offset = 15;
  } else {
    offset = 20;
  }

  return (
    <>
      <div
        data-scroll
        data-scroll-section
        data-scroll-id="games"
        data-scroll-offset={`${offset}%`}
        id="games-container"
        className={twMerge(
          `min-h-[calc(750vh)] w-[80%] max-w-full overflow-hidden rounded bg-grugBlack`,
          "smallDesktop:min-w-[80%]"
        )}
        style={{
          marginTop: `-${offset}vh`,
        }}
      >
        <div
          data-scroll
          data-scroll-sticky
          data-scroll-target="#games-container"
          className="relative"
        >
          <div
            id="games-intro"
            className="pointer-events-none absolute left-0 top-0 z-[2] flex h-screen w-full items-center justify-center bg-grugBlack"
          >
            <div
              data-scroll
              data-scroll-offset="40%"
              className={twMerge(
                style.games_intro_text,
                "min-w-[100vw] max-w-[912px] px-4",
                "smallDesktop:min-w-0 smallDesktop:px-0 smallDesktop:text-center"
              )}
            >
              <h2
                className={twMerge(
                  "mb-10 font-avara text-2xl font-bold text-gray400",
                  "smallDesktop:mb-16 smallDesktop:text-3xl"
                )}
              >
                Our Onchain Games
              </h2>
              <h1
                className={twMerge(
                  "font-avara text-4xl font-extrabold text-white",
                  "smallDesktop:text-5xl"
                )}
              >
                Rising Revenant, Blob Arena, and many more to come
              </h1>
            </div>
          </div>
          <RisingRevenant
            onOpenGallery={(images) => {
              setGalleryImages(images);
              setGalleryVisible(true);
            }}
          />
          <BlobArena
            onOpenGallery={(images) => {
              setGalleryImages(images);
              setGalleryVisible(true);
            }}
          />
        </div>
      </div>
      <ImageGallery
        visible={galleryVisible}
        images={galleryImages}
        onClose={() => setGalleryVisible(false)}
      />
      <Lottie
        id="mouse-scroll-indicator"
        className={twMerge(
          "absolute bottom-[calc(10vh-28px)] left-1/2 z-[1] hidden size-14 -translate-x-1/2 transition-opacity duration-500",
          "smallDesktop:block",
          offset === 15 && "bottom-[calc(7.5vh-28px)]"
        )}
        animationData={mouseScrollLottie}
      />
    </>
  );
};

export default Games;
